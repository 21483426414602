import { Configuration, InteractionRequiredAuthError, PublicClientApplication } from "@azure/msal-browser";

interface MsalResult {
	instance: PublicClientApplication | any;
	apiURL: any;
  theme: any;
  apim:any;
}
  
let msalResult: MsalResult = {
	instance: null,
	apiURL: {},
  theme: {},
  apim: {}
};

export const getApiUrl = (url: string) => {
  if (url.includes('portal.mwcloud.no')) {
    return "https://api.mwcloud.no/portal/config?subscription-key=2794c307cf374de0ba8fef2c792e4a19";
  } else {
    return "https://api-t.mwcloud.no/portal/config?subscription-key=293f5a9e3eba47be97ae8c89005e0e9e";
  }
}

export const getApiConfig =  async (url: string) => {
  const apiUrl = getApiUrl(url);
  const response = await fetch(apiUrl);
  return await response.json();
  
};

export const initializeMsal = async () => {
  try {
    const currentUrl = window.location.href;
    // Determine the environment based on the URL
    let apiConfig;

    apiConfig = await getApiConfig(currentUrl);
    // Create msalConfig object after fetch operation is complete
    const msalConfig: Configuration = {
      auth: apiConfig?.auth
    };

    // Use the configuration to initialize MSAL
    msalResult.instance = new PublicClientApplication(msalConfig);
    msalResult.theme = apiConfig.theme;

    return apiConfig;
  } catch (error) {
    // Handle error
    console.error('Error during MSAL initialization:', error);
    return null;
  }
};

export { msalResult }; // Export the msalInstance directly

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
    scopes: ["User.Read"]
};

export const tokenRequest = {
    scopes: ["User.Read"],

};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};

export const getIdToken = async () => {  
	let msalInstanc: any =  msalResult.instance;

	const activeAccount = msalResult.instance?.getAllAccounts()[0];  
	const requestConfig = { ...tokenRequest, account: activeAccount };  
  
	const response = await (await msalInstanc!)  
		.acquireTokenSilent(requestConfig)  
		.then((res: any) => res.idToken)  
		.catch(async (error: any) => {
			if (error instanceof InteractionRequiredAuthError) { 
				return (await msalInstanc)?.acquireTokenRedirect(loginRequest).then((res: any) => res).catch((error: any) => {
					msalInstanc?.logoutRedirect();
                    return error;
                });  
			} else {
                (await msalInstanc!).loginRedirect();
            }
			return error;  
		});
	return response;  
};

// Set up periodic token refresh to prevent premature session expiration
let tokenRefreshIntervalId: NodeJS.Timeout | null = null;

export const setupTokenRefresh = () => {
  // If already set up, don't do anything
  if (tokenRefreshIntervalId) {
    console.log('[TokenRefresh] Already set up, skipping');
    return;
  }

  const REFRESH_INTERVAL = 60 * 60 * 1000; // 60 minutes (1 hour)
  console.log(`[TokenRefresh] Setting up refresh every ${REFRESH_INTERVAL/60000} minutes`);
  
  const refreshTokenPeriodically = async () => {
    console.log(`[TokenRefresh] ${new Date().toISOString()} - Running scheduled refresh`);
    try {
      // Only attempt to refresh if MSAL is initialized and there's an active account
      if (msalResult.instance) {
        const accounts = msalResult.instance.getAllAccounts();
        console.log(`[TokenRefresh] Found ${accounts.length} accounts`);
        
        const activeAccount = accounts[0];
        if (activeAccount) {
          console.log(`[TokenRefresh] Refreshing token for ${activeAccount.username}`);
          await msalResult.instance.acquireTokenSilent({
            ...tokenRequest,
            account: activeAccount
          });
          console.log(`[TokenRefresh] Token refreshed successfully`);
        } else {
          console.log('[TokenRefresh] No active account found, skipping refresh');
        }
      } else {
        console.log('[TokenRefresh] MSAL instance not initialized yet');
      }
    } catch (error) {
      console.error('[TokenRefresh] Error refreshing token:', error);
    }
  };

  // Set up the interval for token refresh
  tokenRefreshIntervalId = setInterval(refreshTokenPeriodically, REFRESH_INTERVAL);
  console.log('[TokenRefresh] Refresh interval set up successfully');
};  